import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { Heading, headingType, RichText, size } from '@marriott/mi-ui-library';
import { IMerchandisingSmallBannerContent } from '../organisms/MerchandisingSmallBanner/MerchandisingSmallBanner.types';

const MerchandisingSmallBannerDescription = dynamic(() =>
  import('./MerchandisingSmallBannerDescription').then(mod => mod.MerchandisingSmallBannerDescription)
);
const MerchandisingSmallBannerButton = dynamic(() =>
  import('./MerchandisingSmallBannerButton').then(mod => mod.MerchandisingSmallBannerButton)
);
const MerchandisingSmallBannerVariations = dynamic(() =>
  import('./MerchandisingSmallBannerVariations').then(mod => mod.MerchandisingSmallBannerVariations)
);

/**
 * @description MerchandisingSmallBannerContent component have logic for rendering Heading, Description, Variations
 * & CTA sections, it will takes care of whether to show/hide these sections
 * @returns Header, Description, Variations & CTA conditionally
 */
export const MerchandisingSmallBannerContent = (props: IMerchandisingSmallBannerContent) => {
  const {
    isTargetContentLoaded,
    legalLong,
    headerTag,
    headerLong,
    isVariationsAvailable,
    descriptionProps,
    variationProps,
    buttonProps,
    mathType,
  } = props;

  /**
   * @param textRTE refers to rich text element
   * @returns the text by removing the html tags if exist
   */
  const removeParentTagRTE = (textRTE: string): string => {
    return textRTE.substring(textRTE.indexOf('>') + 1, textRTE.lastIndexOf('<')) || headerLong;
  };

  return (
    <div
      className={clsx(
        'merchandising-small-banner__content d-flex flex-column justify-content-between align-items-center flex-grow-1 pl-md-5 mt-3 mt-md-0',
        { 'flex-md-row': !isVariationsAvailable },
        { 'align-items-md-start flex-lg-row': isVariationsAvailable }
      )}
    >
      <div
        data-testid="content-section"
        className={clsx('text-center text-md-left col-12', {
          'col-md-9 pl-0 pr-0 ': !isVariationsAvailable,
          'col-lg-7 px-0': isVariationsAvailable,
          'col-md-12 pd-md-0': mathType?.toLowerCase() === 'currency' && !variationProps?.currencyType,
          'pr-lg-3': !(mathType?.toLowerCase() === 'currency' && !variationProps?.currencyType),
        })}
      >
        <Heading
          customClass={clsx('mb-1 mb-md-0', {
            'skeleton-loader pb-4': !isTargetContentLoaded,
            'd-none': !headerLong && isTargetContentLoaded, // if there is not header long then simple display: none
          })}
          variation={headingType.subtitle}
          fontSize={size.large}
          element={headerTag}
          // Using removeParentTagRTE to clean the text recieved from RTE
          dangerouslySet={isTargetContentLoaded && headerLong?.length > 0 ? removeParentTagRTE(headerLong) : ''}
        />
        <MerchandisingSmallBannerDescription isTargetContentLoaded={isTargetContentLoaded} {...descriptionProps} />

        {isTargetContentLoaded && legalLong && (
          // This Section should be visible only on tab & desktop screens as per designs
          <RichText
            text={legalLong}
            componentId="disclaimer-richtext"
            customClass={clsx('t-font-alt-xs disclaimer-text mt-md-2 d-none d-md-block')}
          />
        )}
      </div>
      <div
        className={clsx({
          'container px-0 pl-lg-5': isVariationsAvailable,
          'd-none': mathType?.toLowerCase() === 'currency' && !variationProps?.currencyType, // if we dont get currencyType in dataLayer but mathType is 'currency' then we should now show this div
          'd-flex flex-column': !(mathType?.toLowerCase() === 'currency' && !variationProps?.currencyType), // for rest all other vriations we need this class
        })}
      >
        {isVariationsAvailable && <MerchandisingSmallBannerVariations {...variationProps} />}
        {isTargetContentLoaded && buttonProps?.callToActionText1 && <MerchandisingSmallBannerButton {...buttonProps} />}
        {isTargetContentLoaded && legalLong && (
          // This Section should be visible only in mobile screens as per design
          <RichText
            text={legalLong}
            componentId="disclaimer-richtext"
            customClass={clsx('t-font-alt-xs disclaimer-text mt-md-2 pt-3', 'd-block d-md-none')}
          />
        )}
      </div>
    </div>
  );
};
