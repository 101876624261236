import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';

import { useDatalayer, useDCADynamicModel } from '@marriott/mi-headless-utils';
import { MerchandisingSmallBannerImage } from '../../molecules/MerchandisingSmallBannerImage';
import { MerchandisingSmallBannerContent } from '../../molecules/MerchandisingSmallBannerContent';
import { StyledMerchandisingSmallBannerUpgraded } from './MerchandisingSmallBanner.styles';
import { IMerchandisingSmallBannerProps } from './MerchandisingSmallBanner.types';

export const MerchandisingSmallBannerComponent = (props: IMerchandisingSmallBannerProps) => {
  const { targetData } = useDCADynamicModel(props.mboxParameter);
  const { mboxParameter } = props;
  const {
    isTargetContentLoaded = mboxParameter && !AuthoringUtils.isInEditor() ? false : true,
    isTargetContentError = false,
    colorScheme = 'color-scheme1',
    logo,
    image,
    customImageClass,
    headerTag,
    headerLong,
    legalLong,
    descriptionLong,
    descriptionLongMobile,
    customDescriptionClass,
    openInNewTab,
    ctaType,
    destinationUrl1,
    callToActionText1,
    trackingProperties,
    styleclass = '',
    componentId,
    openInNewWindowAriaLabel = '',
    customContainerClass = '',
    metaData,
    mathLabel1 = '',
    mathLabel2 = '',
    mathLabel3 = '',
    mathTitle = '',
    mathValue = '',
    mathType = '',
    displayEvergreenContent,
    preview_prop_currency_type = '', // this prop will be used by offers preview components, that only when there is no dataLayer[prop_currency_type] key
  }: IMerchandisingSmallBannerProps = props.mboxParameter &&
  !AuthoringUtils.isInEditor() &&
  targetData?.isTargetContentLoaded
    ? targetData
    : props;

  const { datalayer } = useDatalayer();
  const [variationDetails, setVariationsDetails] = useState({
    isVariationsAvailable: false,
    isPointsVariation: false,
    isCurrencyVariation: false,
    isNightsVariation: false,
  });
  const [isCobrand, setIsCobrand] = useState(false);

  useEffect(() => {
    if (
      isTargetContentLoaded &&
      ((mathType?.toLowerCase() === 'currency' &&
        (!!datalayer?.['prop_currency_type'] || !!preview_prop_currency_type)) ||
        mathType?.toLowerCase() === 'points' ||
        mathType?.toLowerCase() === 'nights')
    ) {
      setVariationsDetails({
        isVariationsAvailable: true,
        isPointsVariation: mathType?.toLowerCase() === 'points',
        isCurrencyVariation:
          mathType?.toLowerCase() === 'currency' &&
          (!!datalayer?.['prop_currency_type'] || !!preview_prop_currency_type),
        isNightsVariation: mathType?.toLowerCase() === 'nights',
      });
    }
  }, [isTargetContentLoaded, mathType]);

  useEffect(() => {
    if (isTargetContentError || !props.mboxParameter) {
      setIsCobrand(trackingProperties?.merchandisingCategory === 'cobrandNonPointsMath');
    } else setIsCobrand(metaData?.type === 'CoBrand');
  }, [isTargetContentError, props?.mboxParameter, metaData]);

  return (
    <StyledMerchandisingSmallBannerUpgraded
      id={componentId}
      data-component-name="o-merch-MerchandisingSmallBanner"
      data-testid="MerchandisingSmallBanner"
      className={clsx('container-fluid position-relative', 'px-4 px-md-3 pt-md-5 pb-5 mt-md-0', `${styleclass}`, {
        'd-none': isTargetContentError && String(displayEvergreenContent) === 'false',
      })}
    >
      <div className="container px-0">
        <div
          className={clsx(
            `merchandising-small-banner t-background-color ${colorScheme} ${customContainerClass}`,
            'd-flex align-items-center flex-column flex-md-row',
            'px-4 pt-0 pt-md-3 pb-4 pb-md-3',
            { 'align-items-md-start': variationDetails.isVariationsAvailable }
          )}
        >
          <MerchandisingSmallBannerImage
            isCobrand={isCobrand}
            logo={logo}
            image={image}
            customImageClass={customImageClass}
            isTargetContentLoaded={isTargetContentLoaded}
            isVariationsAvailable={variationDetails.isVariationsAvailable}
          />
          <MerchandisingSmallBannerContent
            legalLong={legalLong}
            headerTag={headerTag}
            headerLong={headerLong}
            descriptionProps={{
              descriptionLong: descriptionLong,
              descriptionLongMobile: descriptionLongMobile,
              customDescriptionClass: customDescriptionClass,
            }}
            variationProps={{
              currencyType: (datalayer?.['prop_currency_type'] || preview_prop_currency_type) as string,
              currencyTotalStayAmount: parseFloat(`${datalayer?.['res_revenue_total_locale'] ?? '0'}`),
              pointsTotalStayAmount: parseInt(`${datalayer?.['mr_prof_points_balance'] ?? '0'}`, 10),
              currentEliteNightCredits: parseInt(`${datalayer?.['mr_prof_nights_booked_this_year'] ?? '0'}`, 10),
              isPointsVariation: variationDetails.isPointsVariation,
              isCurrencyVariation: variationDetails.isCurrencyVariation,
              isNightsVariation: variationDetails?.isNightsVariation,
              mathLabel1: mathLabel1,
              mathLabel2: mathLabel2,
              mathValue: mathValue,
              mathLabel3: mathLabel3,
              mathTitle: mathTitle,
            }}
            buttonProps={{
              isAuth: datalayer?.['mr_prof_authentication_state'] === 'authenticated',
              ctaType: ctaType,
              openInNewTab: openInNewTab,
              destinationUrl1: destinationUrl1,
              callToActionText1: callToActionText1,
              openInNewWindowAriaLabel: openInNewWindowAriaLabel,
              isVariationsAvailable: variationDetails.isVariationsAvailable,
              targetData: targetData,
              metaData: metaData,
              trackingProperties: trackingProperties,
              isCobrand: isCobrand,
              isTargetContentError: isTargetContentError,
              mboxParameter: mboxParameter,
            }}
            isTargetContentLoaded={isTargetContentLoaded}
            isVariationsAvailable={variationDetails.isVariationsAvailable}
            mathType={mathType}
          />
        </div>
      </div>
    </StyledMerchandisingSmallBannerUpgraded>
  );
};

const MerchandisingSmallBannerConfig = {
  emptyLabel: 'MerchandisingSmallBanner',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/merchandisingsmallbanner`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MerchandisingSmallBanner = (props: any) => {
  return (
    <EditableComponent config={MerchandisingSmallBannerConfig} {...props}>
      <MerchandisingSmallBannerComponent {...props} />
    </EditableComponent>
  );
};
